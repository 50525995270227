import { Col, Container, Image, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { getServerNowUtc } from 'studiokit-scaffolding-js/lib/utils/date'
import CONFIG from '../../configuration'
import purdueLogo from '../Shared/purdue.svg'

import './index.css'

export const Footer = () => {
	return (
		<div className="footer">
			<Container>
				<Row>
					<Col xs={12} lg={5} className="footer-top">
						<a id="purdue-logo" href="http://www.purdue.edu" target="_blank" rel="noopener noreferrer">
							<Image src={purdueLogo} alt="Purdue University" />
						</a>
					</Col>
					<Col xs={12} lg={7} className="footer-bottom">
						<p>
							Circuit v{CONFIG.VERSION} - {CONFIG.VERSION_NAME}, brought to you by{' '}
							<a
								className="link"
								href="http://www.purdue.edu/purdue/disclaimer.html"
								target="_blank"
								rel="noopener noreferrer">
								Purdue&nbsp;University
							</a>
							{'.'}
							<br /> {String.fromCharCode(169)} {getServerNowUtc().getFullYear()} Purdue University. An
							equal access, equal opportunity university.
							<br />
							Need help? Have trouble accessing this page because of a disability?{' '}
							<a className="link" href="mailto:tlt@purdue.edu">
								Contact&nbsp;us.
							</a>
							<br />
							Read the <Link to={'/privacy-policy'}>privacy policy</Link>.
						</p>
					</Col>
				</Row>
			</Container>
		</div>
	)
}
