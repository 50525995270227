import { cloneDeep, unset } from 'lodash'
import { MODEL_FETCH_RESULT_ACTION_TYPE, ModelFetchResultAction } from 'studiokit-scaffolding-js'

/**
 * This reducer removes phases that may be in an assignment or group assignment's phases collection
 * but are being removed by way of omission in the incoming result set. This reducer should be
 * included in the models reducer (via reduceReducers) but run after the main models reducer in
 * studiokit-scaffolding-js. In an ideal world, we'd have a systematic way to remove specific omitted
 * incoming keys via studiokit-scaffolding-js while preserving other keys.
 * @param {object} action - the incoming action from the back end
 * @returns {object} Modified state with assignment or group assignment phases removed that were not
 * in the incoming results
 */
export default function phaseReducer(state: Record<string, any> = {}, action: ModelFetchResultAction) {
	switch (action.type) {
		case MODEL_FETCH_RESULT_ACTION_TYPE.FETCH_RESULT_RECEIVED: {
			const isAssignment = !!state.assignments && /^assignments\.?\d*$/.test(action.modelPath)
			const isGroupAssignment = !!state.groupAssignments && /^groupAssignments\.?\d*$/.test(action.modelPath)
			if (isAssignment || isGroupAssignment) {
				const clonedState = cloneDeep(state)
				const keyName = isAssignment ? 'assignments' : 'groupAssignments'
				const phaseKeyName = isAssignment ? 'phases' : 'groupAssignmentPhases'
				const ids = action.modelPath === keyName ? Object.keys(action.data) : [action.data.id]

				ids.forEach(id => {
					!!state[keyName][id] &&
						!!state[keyName][id]._metadata &&
						!state[keyName][id]._metadata.isFetching &&
						!state[keyName][id]._metadata.hasError &&
						// existing phases
						Object.keys(state[keyName][id][phaseKeyName])
							// except ones also in incoming
							.filter(
								p =>
									!Object.keys(
										(action.modelPath === keyName ? action.data[id] : action.data)[phaseKeyName]
									).includes(p)
							)
							.map(removedPhase =>
								unset(clonedState, `${keyName}[${id}][${phaseKeyName}][${removedPhase}]`)
							)
				})
				return clonedState
			} else {
				return state
			}
		}
		default:
			return state
	}
}
