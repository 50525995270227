import Bulb from '@material-ui/icons/EmojiObjects'
import Launch from '@material-ui/icons/Launch'
import Settings from '@material-ui/icons/Settings'
import { Component } from 'react'
import { Dropdown } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { UserDropdown as BaseUserDropdown } from 'studiokit-scaffolding-js/lib/components/Dropdowns'
import { UserDropdownProps } from 'studiokit-scaffolding-js/lib/components/Dropdowns/UserDropdown'
import { EXTERNAL_HELP_RESOURCE_PAGE_URL } from '../../../constants/externalLinks'

import '../index.css'

export class UserDropdown extends Component<UserDropdownProps> {
	render() {
		const { userInfo } = this.props

		return (
			<BaseUserDropdown userInfo={userInfo} impersonationIconWrapperClassName="bg-color-pink color-white">
				<LinkContainer to="/settings">
					<Dropdown.Item>
						<Settings className="menu-icon fill-white" />
						Settings
					</Dropdown.Item>
				</LinkContainer>
				<LinkContainer to="/help/scoring-docs">
					<Dropdown.Item>
						<Bulb className="menu-icon fill-white" />
						How does Circuit work?
					</Dropdown.Item>
				</LinkContainer>
				<Dropdown.Item target="_blank" rel="noopener noreferrer" href={EXTERNAL_HELP_RESOURCE_PAGE_URL}>
					<Launch className="menu-icon fill-white" />
					Help
				</Dropdown.Item>
			</BaseUserDropdown>
		)
	}
}
