/* eslint-disable import/first,react-refresh/only-export-components */
/* eslint-disable import/first */
/* eslint-disable import/order */
import {
	createGenerateClassName,
	createMuiTheme,
	jssPreset,
	MuiThemeProvider,
	StylesProvider
} from '@material-ui/core/styles'
import { ConnectedRouter } from 'connected-react-router'
import { create } from 'jss'
import katex from 'katex'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'
import reduceReducers from 'reduce-reducers'
import { Reducer } from 'redux'
import { PersistGate } from 'redux-persist/integration/react'
import AccessibleAppComponent from 'studiokit-scaffolding-js/lib/components/HOC/AccessibleAppComponent'
import AsyncComponent from 'studiokit-scaffolding-js/lib/components/HOC/AsyncComponent'
import { registerQuill } from 'studiokit-scaffolding-js/lib/components/Quill'
import phaseReducer from './redux/reducers/phaseReducer'
import { onPostLoginSaga } from './redux/sagas/onPostLoginSaga'
import { ReduxState } from './types'
import * as serviceWorker from './utils/serviceWorker'

// polyfills
import 'core-js'

//#region CSS

import 'bootstrap/dist/css/bootstrap.min.css'
import 'katex/dist/katex.min.css'
import 'react-quill/dist/quill.snow.css'
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'
import 'react-table/react-table.css'
import 'studiokit-scaffolding-js/lib/css/variables.css'
import './css/variables.css'
import 'studiokit-scaffolding-js/lib/css/index.css'
import './css/index.css'

// `react-scripts@2.x` wants to minify bootstrap and tachyon css together
// causing tachyons to load _before_ index.css, which breaks hierarchy
// using `AsyncComponent` and this dummy component forces them to be split
const Tachyons = AsyncComponent(() => import('./components/Tachyons'), false)

//#endregion CSS

//#region globals

// @ts-ignore: vite does not provide node global value like create-react-app did.
window.global ||= window

//#endregion globals

//#region Quill

// hack: quill uses window.katex to render the formula module. Set katex in here.
const windowWithoutType = window as any
windowWithoutType.katex = katex

registerQuill()

//#endregion Quill

//#region JSS + material-ui

const generateClassName = createGenerateClassName()
const insertionPoint = document.getElementById('jss-insertion-point')
const jss = create({
	...jssPreset(),
	// We define a custom insertion point that JSS will look for injecting the styles in the DOM.
	insertionPoint: insertionPoint || '#jss-insertion-point'
})

const theme = createMuiTheme({
	palette: {
		type: 'light',
		primary: {
			light: '#c3d8e0',
			main: '#007aa3',
			dark: '#00a0d3'
		},
		error: {
			main: '#d0021b'
		}
	},
	overrides: {
		MuiTypography: {
			body1: {
				letterSpacing: 'normal'
			},
			body2: {
				letterSpacing: 'normal'
			}
		},
		MuiButton: {
			root: {
				minWidth: '88px',
				minHeight: '36px',
				letterSpacing: 'normal'
			},
			contained: {
				backgroundColor: 'white',
				'&:hover': {
					backgroundColor: 'rgba(0, 0, 0, 0.08)'
				}
			}
		},
		MuiTableCell: {
			root: {
				padding: '4px 24px'
			}
		},
		MuiTouchRipple: {
			rippleVisible: {
				opacity: 0.3
			},
			child: {
				opacity: 0.3
			}
		},
		MuiListItem: {
			root: {
				paddingTop: '12px',
				paddingBottom: '12px'
			}
		}
	},
	shape: {
		borderRadius: 3
	}
})

//#endregion Jss + material-ui

//#region App

// (imported after other css)
import App from './components/App'

// Note: override TOwnProps generic, since all `AppProps` are provided by the internal `connect()`
const Application = AccessibleAppComponent<RouteComponentProps>(App)

//#endregion App

//#region Scaffolding

import {
	BaseModelsState,
	ModelStoreAction,
	setConfigureModelsReducer,
	setOnPostLoginSaga,
	startup
} from 'studiokit-scaffolding-js'
import configuration from './configuration'
import endpointMappings from './endpointMappings'

setConfigureModelsReducer(<TModelsState extends BaseModelsState>(reducer: Reducer<TModelsState, ModelStoreAction>) => {
	return (reduceReducers(reducer, phaseReducer as Reducer) as unknown) as Reducer<TModelsState, ModelStoreAction>
})

setOnPostLoginSaga(onPostLoginSaga)

const containerElementId = 'root'
const { history, store, persistor } = startup<ReduxState>(configuration, endpointMappings)

//#endregion Scaffolding Startup

ReactDOM.render(
	<Provider store={store} key="provider">
		<PersistGate loading={null} persistor={persistor}>
			<StylesProvider jss={jss} generateClassName={generateClassName}>
				<MuiThemeProvider theme={theme}>
					<ConnectedRouter history={history}>
						<>
							<Tachyons />
							<Application />
						</>
					</ConnectedRouter>
				</MuiThemeProvider>
			</StylesProvider>
		</PersistGate>
	</Provider>,
	document.getElementById(containerElementId)
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
